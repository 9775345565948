import { FunctionComponent } from "react";

import { IProps } from "./popover-provider.types";
import PopoverContext, { defaultContext } from "../popover-context/popover-context";

/**
 * Wraps the children in a PopoverContext.Provider
 * @param children The children to wrap in the PopoverContext.Provider
 * @param context The context to merge with the default context
 * @returns The children wrapped in a PopoverContext.Provider
 */
const PopoverProvider: FunctionComponent<IProps> = ({ children, context }) => (
    <PopoverContext.Provider value={{ ...defaultContext, ...context }}>{children}</PopoverContext.Provider>
);

export default PopoverProvider;
