import openSelectMenu from "./open-select-menu";
import defaultUserEvent from "@testing-library/user-event";
import { Matcher, screen, waitFor, within } from "@testing-library/react";
import { act } from "react";

export interface ISelectOptions {
    useActOnClick?: boolean;
    menuMatcher?: Matcher | HTMLElement;
    readonly userEventInstance?: ReturnType<typeof defaultUserEvent.setup>;
}

/**
 * Open a select menu and choose one or more options.
 * @param matcherOrMatchers One or more matchers to find labels.
 * @param menuMatcher       Optional matcher to find the menu in case there are multiple.
 */
const select = async (matcherOrMatchers: Matcher | Matcher[], options: ISelectOptions = {}) => {
    const { useActOnClick, ...otherOptions } = options;
    const { userEventInstance } = otherOptions;
    const userEvent = userEventInstance ?? defaultUserEvent;

    const matchers = Array.isArray(matcherOrMatchers) ? matcherOrMatchers : [matcherOrMatchers];

    const list = await openSelectMenu(otherOptions);

    // In case of a multiple selection, the apply button needs to be clicked too.
    const apply = screen.queryByRole("button", { name: /apply/i });

    // Select all items by label.
    for (const matcher of matchers) {
        const item = within(list).getByLabelText(matcher);

        if (useActOnClick) {
            await act(async () => await userEvent.click(item));
        } else {
            await userEvent.click(item);
        }

        // Wait for selection to happen.
        if (apply !== null) {
            await waitFor(() => typeof within(list).queryByLabelText(matcher) === "undefined");
        }
    }

    if (apply !== null) {
        await userEvent.click(apply);
    }
};

export default select;
