import { FunctionComponent, memo } from "react";
import { useSelector } from "react-redux";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IApplicationState } from "redux/types";
import { IProps } from "./chat-link-indicators.types";
import { NavigationLinkIndicator } from "@edgetier/client-components";

/**
 * Link to the chat screen. Its appearance will change depending on if there are unread messages or not.
 */
const ChatLinkIndicators: FunctionComponent<IProps> = () => {
    const { hasInvites, isChatScreenOpen, isChatEnabled } = useSelector<
        IApplicationState,
        { hasInvites: boolean; isChatScreenOpen: boolean; isChatEnabled: boolean }
    >(({ chat }) => ({
        hasInvites: Object.keys(chat.invites).length > 0,
        isChatScreenOpen: chat.isChatScreenOpen,
        isChatEnabled: chat.enabled,
    }));

    if (!hasInvites || isChatScreenOpen || !isChatEnabled) {
        return null;
    }

    return (
        <NavigationLinkIndicator>
            <FontAwesomeIcon className="navigation__chat-link-invites__icon" icon={faUserPlus} />
        </NavigationLinkIndicator>
    );
};

export default memo(ChatLinkIndicators);
