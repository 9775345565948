import { createContext } from "react";
import { doNothing } from "@edgetier/utilities";
import { IPopoverContext } from "./popover-context.types";

export const defaultContext: IPopoverContext = {
    close: doNothing,
    isLockedOpen: false,
    isOpen: false,
    lockOpen: doNothing,
    unlock: doNothing,
};

const PopoverContext = createContext(defaultContext);
export default PopoverContext;
