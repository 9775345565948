import { useCallback, useRef, useState } from "react";
// eslint-disable-next-line import/no-unassigned-import
import "./use-scroll-with-shadows.scss";

/**
 * Custom hook to add a shadow to the top and bottom of a scrollable container.
 *
 * @returns An object containing the class name for the scrollable container and the scroll event handler
 */
export const useScrollWithShadow = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);
    const localRef = useRef<HTMLDivElement | null>(null);

    const onScrollHandler = (event: any) => {
        setScrollTop(Math.ceil(event.target.scrollTop));
        setScrollHeight(event.target.scrollHeight);
        setClientHeight(event.target.clientHeight);
    };

    const getClassname = useCallback(() => {
        const isBottom = clientHeight === scrollHeight - scrollTop;
        const isTop = scrollTop === 0;
        const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

        let classname = "scroll-with-shadows--none";
        const defaultClassName = "scroll-with-shadows";
        const top = "scroll-with-shadows--top";
        const bottom = "scroll-with-shadows--bottom";
        const between = "scroll-with-shadows--between";

        if (scrollHeight > clientHeight) {
            if (isTop) {
                classname = bottom;
            } else if (isBetween) {
                classname = between;
            } else if (isBottom) {
                classname = top;
            }
        }
        return `${defaultClassName} ${classname}`;
    }, [scrollTop, scrollHeight, clientHeight]);

    return {
        classname: getClassname(),
        onScroll: onScrollHandler,
        ref: (newRef: HTMLDivElement) => {
            if (newRef !== null) {
                setScrollTop(Math.ceil(newRef.scrollTop));
                setScrollHeight(newRef.scrollHeight);
                setClientHeight(newRef.clientHeight);
                localRef.current = newRef;
            }
        },
    };
};
