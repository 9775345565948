import { FunctionComponent, memo } from "react";
import { IProps } from "./popover-content.types";
import { useScrollWithShadow } from "~/use-scroll-with-shadow";
import "./popover-content.scss";

/**
 * A React component that renders content within a popover with scroll functionality and shadows.
 *
 * @param children - The content to be displayed inside the popover
 * @returns  A div element containing the popover content with scroll shadows
 */
const PopoverContent: FunctionComponent<IProps> = ({ children }) => {
    const { classname, ...other } = useScrollWithShadow();
    return (
        <div data-testid="popover-content" className={`popover-content ${classname}`} {...other}>
            {children}
        </div>
    );
};

export default memo(PopoverContent);
