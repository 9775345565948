export const MINIMUM_MULTIPLE_WIDTH = 600;
export const MINIMUM_SINGLE_WIDTH = 300;

export const MAXIMUM_TOOLTIP_ITEMS = 30;

export const MINIMUM_TAGS_TO_OPEN_ACCORDIONS = 11;

export const APPLY_BUTTON_TEXT = "Apply";
export const CANCEL_BUTTON_TEXT = "Cancel";

export const LIMIT_REACHED_MESSAGE = "Item limit reached";

export const RENDERED_VIRTUALIZE_ELEMENTS = 10;
export const DEFAULT_VIRTUALIZED_ITEM_HEIGHT = 41;
