import classNames from "classnames";
import { FunctionComponent, memo } from "react";

import "./page-content.scss";
import { IProps } from "./page-content.types";

/**
 * Main content of a page.
 * @param props.children Page components.
 * @returns              Page content.
 */
const PageContent: FunctionComponent<IProps> = ({ children, width = "standard", fullHeight = false }) => {
    return (
        <div className="page-content">
            <div
                className={classNames("page-content__container", {
                    [`page-content__container--${width}`]: typeof width === "string",
                    "page-content__container--full-height": fullHeight,
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default memo(PageContent);
