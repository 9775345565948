import { FunctionComponent, memo, useMemo } from "react";
import { IProps } from "./assignment-details-field.types";
import { LoadingSkeleton } from "@edgetier/client-components";
import useUser from "hooks-for/users/use-user";
import { isAfter } from "date-fns";
import classNames from "classnames";
import { getSplitTimestamp } from "@edgetier/utilities";
import { AssignmentDetailsRemoveAgent } from "./assignment-details-remove-agent";
import VerifyPermission from "components-for/verify-permission";
import { Permission } from "@edgetier/types";
import "./assignment-details-field.scss";

/**
 * Show details about an email assignment.
 * @param props.assignment The assignment to be displayed.
 * @param props.interactionCompleted Boolean flag for checking if an interaction is considered complete
 * @param props.emailId
 * @param props.isLastAssignment Boolean flag for checking if the assignment is the only one active
 */
const AssignmentDetailsField: FunctionComponent<IProps> = ({
    assignment,
    interactionCompleted,
    emailId,
    isLastAssignment,
}) => {
    const { data: assignedUser, isLoading: isAssignedUserLoading } = useUser(assignment.userId);
    const { data: assignedByUser, isLoading: isAssignedByUserLoading } = useUser(
        assignment.assignedByUserId ?? 0,
        {},
        { enabled: assignment.assignedByUserId !== null }
    );

    const isActive = useMemo(
        () =>
            !interactionCompleted &&
            (assignment.assignedUntil === null || isAfter(new Date(assignment.assignedUntil), new Date())),
        [assignment.assignedUntil, interactionCompleted]
    );

    return (
        <div className="assignment-details-field" role="rowgroup">
            <div className="assignment-details-field__group">
                <div className="assignment-details-field__date">
                    <div>{`${assignedUser?.firstName} ${assignedUser?.surname}`}</div>
                    <div>
                        {"- until "}
                        {`${
                            assignment.assignedUntil !== null
                                ? getSplitTimestamp(new Date(assignment.assignedUntil))
                                : "complete"
                        }`}
                    </div>
                </div>
                <div className="assignment-details-field__text">
                    {isAssignedUserLoading || isAssignedByUserLoading ? (
                        <LoadingSkeleton />
                    ) : (
                        <>
                            {`${getSplitTimestamp(new Date(assignment.createDateTime))}`}
                            {" by "}
                            {`${
                                assignment.automaticallyAssigned
                                    ? "system"
                                    : `${assignedByUser?.firstName} ${assignedByUser?.surname}`
                            }`}
                        </>
                    )}
                </div>
            </div>
            <div
                className={classNames("assignment-details-field__status", {
                    "assignment-details-field__status--active": isActive,
                })}
            >
                {isActive ? "Active" : "Expired"}
            </div>
            {isActive && (
                <VerifyPermission permission={Permission.AssignInteraction}>
                    <AssignmentDetailsRemoveAgent
                        emailId={emailId}
                        userId={assignment.userId}
                        isLastAssignment={isLastAssignment}
                        emailUserAssignmentId={assignment.emailUserAssignmentId}
                    />
                </VerifyPermission>
            )}
        </div>
    );
};

export default memo(AssignmentDetailsField);
