import { IPaginated } from "@edgetier/types";

import { IBackendModel, IItem, InteractionMetric, InteractionType, TimePeriodGroupBy } from "../common";
import { AgentStateType } from "./agent-states";
import { IAgent } from "./agents";
import { MessageDirection, WritingIssueType } from "./messages";
import { ITag } from "./tags";

interface IDynamicKeyField<T = any> {
    readonly [index: string]: T;
}

export interface IInteractionMetrics extends IDynamicKeyField {
    readonly agentMessagesCount: number | null;
    readonly agentFirstResponseTimeSeconds: number | null;
    readonly agentSatisfaction: number | null;
    readonly agentScore: number | null;
    readonly botMessagesCount: number | null;
    readonly customerMessagesCount: number | null;
    readonly customerSatisfaction: number | null;
    readonly queueTimeSeconds: number | null;
    readonly handlingTimeSeconds: number | null;
    readonly experienceScore: number | null;
    readonly messagesCount: number | null;
    readonly netPromoterScore: number | null;
    readonly firstResponseTimeSeconds: number | null;
    readonly isQueryResolved: boolean | null;
    readonly serviceLevelAgreementPercentage: number | null;
}

export type INumericInteractionMetric = Exclude<InteractionMetric, InteractionMetric.QueryResolution>;

// Minimum and maximum query parameters for every metric.
export type IInteractionMaximumMetricParameters = {
    [Property in INumericInteractionMetric as `maximum${Capitalize<string & Property>}`]?: number;
};

export type IInteractionMinimumMetricParameters = {
    [Property in INumericInteractionMetric as `minimum${Capitalize<string & Property>}`]?: number;
};

export enum InteractionState {
    Blocked = "BLOCKED",
    Complete = "COMPLETE",
    Deflecting = "DEFLECTING",
    Handling = "HANDLING",
    Queuing = "QUEUING",
    WrapUp = "WRAP_UP",
}
export enum TextSearchType {
    Simple = "simple",
    Vector = "vector",
    OpenSearch = "open_search",
}

export type IInteractionsGetQueryParameters = {
    readonly agentId?: string | string[];
    readonly anomalyId?: string;
    readonly contactId?: string;
    readonly agentStateType?: AgentStateType[];
    readonly agentTagGroupId?: string;
    readonly agentTagId?: string[];
    readonly endDateTime?: Date;
    readonly groupBy?: TimePeriodGroupBy | TimePeriodGroupBy[] | string | string[];
    readonly hasFrustration?: boolean;
    readonly hasGratitude?: boolean;
    readonly hasPraise?: boolean;
    readonly hasConfusion?: boolean;
    readonly hasEmpathy?: boolean;
    readonly highlightMaximumWords?: number;
    readonly highlightMinimumWords?: number;
    readonly interactionState?: InteractionState[];
    readonly interactionType?: InteractionType[];
    readonly isAgentSearch?: boolean;
    readonly isBotSearch?: boolean;
    readonly isCustomerSearch?: boolean;
    readonly isMessageSearch?: boolean;
    readonly isMetadataSearch?: boolean;
    readonly isQueryResolved?: boolean;
    readonly issue?: string;
    readonly isSystemSearch?: boolean;
    readonly languageIsoCode?: string;
    readonly limit?: number;
    readonly messageDirections?: MessageDirection[];
    readonly orderBy?: string;
    readonly parameterGroupId?: string;
    readonly search?: string;
    readonly searchType?: string;
    readonly similarityThreshold?: number;
    readonly startDateTime?: Date;
    readonly tagGroupId?: string;
    readonly tagId?: string[];
    readonly textSearchType?: TextSearchType;
    readonly timeZone?: string;
    readonly webSearchSyntax?: boolean;
    readonly writingIssueType?: WritingIssueType;
    readonly beforeInteractionId?: string;
    readonly afterInteractionId?: string;
    readonly relativeDateTime?: string;
} & IInteractionMaximumMetricParameters &
    IInteractionMinimumMetricParameters;

export interface IInteractionAudioResponse {
    readonly url: string | null;
}
export interface IInteractionDetailsResponse {
    readonly agentId: string | null;
    readonly blockedDateTime: Date | null;
    readonly clientId: string;
    readonly contactId: string | null;
    readonly contentCleaned: string;
    readonly contentHtml: string;
    readonly contentPlain: string;
    readonly endDateTime: Date;
    readonly externalReference: string;
    readonly enterQueueDateTime: Date | null;
    readonly exitQueueDateTime: Date | null;
    readonly handleDateTime: Date | null;
    readonly interactionId: string;
    readonly interactionType: InteractionType;
    readonly languageIsoCode: string;
    readonly senderEmail: string | null;
    readonly senderName: string | null;
    readonly senderReference: string;
    readonly startDateTime: Date;
    readonly subject: string;
    readonly surveyedInteractionId: string | null;
    readonly tags: ITag[];
    readonly url: string;
}

type IInteractionsMaximumMetricParameters = {
    [Property in IInteractionMinimumMetricParameters as `interaction${Capitalize<string & Property>}`]?: number;
};

type IInteractionsMinimumMetricParameters = {
    [Property in IInteractionMaximumMetricParameters as `interaction${Capitalize<string & Property>}`]?: number;
};

export type IInteractionMessagesGetQueryParameters = {
    readonly agentId?: string | string[];
    readonly groupBy?: TimePeriodGroupBy;
    readonly limit?: number;
    readonly isMessageSearch?: boolean;
    readonly interactionType?: string[];
    readonly timeZone?: string;
    readonly webSearchSyntax?: boolean;
    readonly tagGroupId?: string;
    readonly issue?: string;
    readonly writingIssueType?: WritingIssueType;
} & {
    [Property in keyof Pick<
        IInteractionsGetQueryParameters,
        | "agentId"
        | "anomalyId"
        | "endDateTime"
        | "hasFrustration"
        | "hasGratitude"
        | "hasPraise"
        | "hasConfusion"
        | "hasEmpathy"
        | "highlightMaximumWords"
        | "highlightMinimumWords"
        | "isAgentSearch"
        | "isBotSearch"
        | "isCustomerSearch"
        | "isMetadataSearch"
        | "isSystemSearch"
        | "languageIsoCode"
        | "search"
        | "startDateTime"
        | "tagId"
        | "agentTagId"
        | "textSearchType"
    > as `interaction${Capitalize<string & Property>}`]?: IInteractionsGetQueryParameters[Property];
} & IInteractionsMaximumMetricParameters &
    IInteractionsMinimumMetricParameters;

export interface IInteractionVariable {
    readonly interactionVariableId: string;
    readonly interactionVariable: string;
    readonly value: string;
}

export interface IInteraction extends IBackendModel {
    readonly agent: Pick<IAgent, "agentId" | "name"> | null;
    readonly contentCleaned: string;
    readonly enterQueueDateTime: Date | null;
    readonly exitQueueDateTime: Date | null;
    readonly handleDateTime: Date | null;
    readonly hasFrustration: boolean;
    readonly hasPraise: boolean;
    readonly hasGratitude: boolean;
    readonly hasConfusion: boolean;
    readonly hasEmpathy: boolean;
    readonly interactionId: string;
    readonly interactionState: InteractionState;
    readonly interactionType: InteractionType;
    readonly preview: string[];
    readonly searchMatch: string | null;
    readonly startDateTime: Date;
    readonly subject: string | null;
    readonly metrics: IInteractionMetrics;
    readonly url: string;
    readonly contactId: string | null;
    readonly languageIsoCode: string;
    readonly clientId: string | null;
}

export interface IInteractionsCount<Group> {
    readonly group: Group;
    readonly count: number | null;
    readonly proportion?: number;
    readonly dateTime?: Date | string;
}

export interface IInteractionTagsRequest {
    readonly items: IDynamicKeyField<string>;
}

export type IInteractionVariablesValues = IItem<IInteractionVariable>;

export type IInteractionsGetResponse = IPaginated<IInteraction>;

export type IInteractionsGetCsvResponse = string;

export type IInteractionsCountResponse<Group> = IItem<IInteractionsCount<Group>>;

export type IInteractionTagsResponse = IItem<IDynamicKeyField<string>>;

export type IInteractionVariablesGetResponse = IInteractionVariablesValues;

export type IInteractionMetricsAvailableGetQueryParameters = {
    readonly interactionType?: InteractionType[];
};

export type IInteractionMetricsAvailable = {
    readonly [Property in InteractionMetric]: boolean;
};

export interface IInteractionSummaryResponse {
    readonly summary: string[];
}
