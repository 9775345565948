import ChatLinkIndicators from "./chat-link-indicators";
import "./chat-link.scss";
import { NavigationLink } from "@edgetier/client-components";
import { faComments } from "@fortawesome/pro-solid-svg-icons";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";
import { useSelector } from "react-redux";
import type { IApplicationState } from "redux/types";
import { NavigationItemNotificationCount } from "components-for/navigation/navigation-item-notification-count";
import { getUnreadMessageCount } from "redux/modules/chat/chat-selectors";
import { FunctionComponent, memo } from "react";
import { IProps } from "components/search-emails/search-emails.types";

/**
 * Link to the chat screen. This shows unread message and invite indicators.
 * @returns Link to the chat screen.
 */
const ChatLink: FunctionComponent<IProps> = () => {
    const { unreadMessageCount, isChatScreenOpen, isChatEnabled } = useSelector<
        IApplicationState,
        { unreadMessageCount: number; isChatScreenOpen: boolean; isChatEnabled: boolean }
    >(({ chat }) => ({
        unreadMessageCount: getUnreadMessageCount(chat),
        isChatScreenOpen: chat.isChatScreenOpen,
        isChatEnabled: chat.enabled,
    }));

    const shouldShowNotification = !isChatScreenOpen && unreadMessageCount > 0 && isChatEnabled;

    return (
        <NavigationLink
            label="Chat"
            icon={faComments}
            page={AbsolutePaths[PageNames.Chat]()}
            indicator={
                shouldShowNotification && <NavigationItemNotificationCount notificationCount={unreadMessageCount} />
            }
        >
            <ChatLinkIndicators />
        </NavigationLink>
    );
};

export default memo(ChatLink);
