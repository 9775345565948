import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Button } from "@edgetier/components";

import { DropDownMenu, DropDownMenuItem } from "~/drop-down-menu";
import { IProps } from "./split-button.types";
import "./split-button.scss";

/**
 * A button and a dropdown menu, the default option can be selected by clicking the button
 * and all other options are selected through the dropdown.
 * @param props.options              Options to show in the dropdown.
 * @param props.defaultOption        Default option which will be shown in the button.
 * @param props.onOptionSelect       Function that runs when an option is selected.
 * @param props.getOptionLabel       Function to get the label of an option.
 * @param props.getOptionDisabled    Function to get the disabled state of an option.
 * @param props.shouldButtonDoAction Boolean to control if the button should do an action.
 * @param props.disabled             Boolean to control if the button is disabled.
 * @param props.getOptionIcon        Function to get the icon of an option.
 * @param props.type                 The type of the button.
 */
const SplitButton = <T extends {}>({
    options,
    defaultOption = options[0],
    onOptionSelect,
    getOptionLabel,
    getOptionDisabled,
    shouldButtonDoAction = true,
    disabled,
    getOptionIcon,
    type = "button",
    ...buttonProps
}: IProps<T>) => {
    /**
     * Get the label associated with a particular option.
     * @param option The option, which the label is needed for.
     */
    const getLabel = (option: T) => {
        const label = getOptionLabel ? getOptionLabel(option) : option.toString();
        return type === "submit" ? `Submit as ${label}` : label;
    };

    /**
     * Get the icon associated with a particular option.
     * @param option The option whose icon is being retrieved.
     */
    const getIcon = (option: T) => {
        return getOptionIcon ? getOptionIcon(option) : faCheck;
    };

    // Boolean to control if the dropdown should be shown based on how many options are available.
    const hasDropdownOptions = options.length > 1;

    return (
        <div
            className={classNames("button--split", {
                "button--split--submit": type === "submit",
                "button--split--drop-down-only": hasDropdownOptions && !shouldButtonDoAction,
                "button--split--no-dropdown-options": !hasDropdownOptions,
                "button--split--negative": buttonProps.styleName === "negative",
                "button--split--positive": buttonProps.styleName === "positive",
            })}
        >
            <div className="button--split__container">
                <Button
                    onClick={() => shouldButtonDoAction && onOptionSelect?.(defaultOption)}
                    disabled={disabled || !shouldButtonDoAction || getOptionDisabled?.(defaultOption)}
                    icon={getIcon(defaultOption)}
                    type="button"
                    {...buttonProps}
                >
                    {getLabel(defaultOption)}
                </Button>

                {hasDropdownOptions && (
                    <DropDownMenu useIcon={false} disabled={disabled}>
                        <ul>
                            {options
                                .filter((option) => option !== defaultOption)
                                .map((option, index) => (
                                    <DropDownMenuItem
                                        key={index}
                                        isDisabled={(disabled || getOptionDisabled?.(option)) ?? false}
                                        icon={getIcon(option)}
                                        onClick={() => onOptionSelect?.(option)}
                                    >
                                        <button type="button">{getLabel(option)}</button>
                                    </DropDownMenuItem>
                                ))}
                        </ul>
                    </DropDownMenu>
                )}
            </div>
        </div>
    );
};

export default SplitButton;
