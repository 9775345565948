import toast from "react-hot-toast";
import { AxiosError, AxiosInstance } from "axios";
import { getServerError, getTimeToRead } from "@edgetier/utilities";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { IRole } from "@edgetier/types";
import urljoin from "url-join";

/**
 * Create request for removing a role
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param role
 */
const removeRole = (routeUrl: string, axiosInstance: AxiosInstance, role: IRole) => async () => {
    const { data } = await axiosInstance.delete<IRole>(urljoin(routeUrl, role.roleId.toString()));
    return { ...data };
};

/**
 * Custom hook to remove an existing role
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param role              The role intended to be removed
 * @param configuration     Optional Axios configuration
 */
const useRemoveRole = (
    routeUrl: string,
    axiosInstance: AxiosInstance,
    role: IRole,
    configuration: UseMutationOptions<IRole, AxiosError, IRole> = {}
) => {
    const queryClient = useQueryClient();

    const onSuccess = () => {
        queryClient.setQueryData<IRole[]>([routeUrl], (roles): IRole[] => {
            return (roles ?? []).filter((existingRole) => existingRole.roleId !== role.roleId);
        });
        const successMessage = `Successfully removed ${role.role}`;
        toast.success(successMessage, { duration: getTimeToRead(successMessage, 1000) });
    };
    const onError = (error: unknown) => {
        toast.error(getServerError(error, "Failed to remove a role"));
    };

    const mutationSettings = { onSuccess, onError, ...configuration };
    return useMutation(removeRole(routeUrl, axiosInstance, role), mutationSettings);
};

export default useRemoveRole;
