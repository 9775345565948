import { FunctionComponent, memo } from "react";
import { IProps } from "./select-info-message.types";
import { Box } from "@edgetier/client-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import "./select-info-message.scss";

/**
 * Component for displaying a message in the select menu (will be displayed if the infoMessage callbackFn returns true)
 * @param props.message - The message to display
 * @returns {JSX.Element} - The SelectInfoMessage component
 */
const SelectInfoMessage: FunctionComponent<IProps> = ({ message }) => {
    return (
        <Box className="select-info-message">
            <div className="select-info-message__content">
                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                <span>{message}</span>
            </div>
        </Box>
    );
};

export default memo(SelectInfoMessage);
