import { SelectField } from "@edgetier/select";
import useSkills from "hooks-for/skills/use-skills";
import { ISelectSkillItem } from "redux/application.types";
import { IProps } from "./select-skill.types";

/**
 * Drop-down to select one or more skills.
 * @returns Select component.
 */
const SelectSkill = ({ name = "skillId", parameters = {}, skillOptions, ...otherProps }: IProps) => {
    const hasSkillOptions = typeof skillOptions !== "undefined" && Array.isArray(skillOptions);

    const { data: skills, isLoading } = useSkills(parameters, {
        enabled: !hasSkillOptions,
        select: (skills) => skills.sort((first, second) => first.skill.localeCompare(second.skill)),
    });

    return (
        <SelectField<ISelectSkillItem, number>
            description="skill"
            getLabel={({ skill }) => skill}
            getValue={({ skillId }) => skillId}
            isLoading={isLoading}
            items={hasSkillOptions ? skillOptions : skills || []}
            name={name}
            {...otherProps}
        />
    );
};

export default SelectSkill;
