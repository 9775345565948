import { doNothing, isScrollingElementVisible } from "@edgetier/utilities";
import { useCombobox, useMultipleSelection } from "downshift";
import { useState } from "react";
import { IProps as ISelectProps } from "../select.types";
import { stateReducer } from "./use-select-setup.utilities";

export type ISelectSetupConfig<IItem extends {}, IValue = string> = Pick<
    ISelectProps<IItem, IValue>,
    | "disableMenuItems"
    | "getLabel"
    | "getValue"
    | "isMultipleSelectCheckbox"
    | "inputId"
    | "isSingleSelect"
    | "isSourcedSelect"
    | "items"
    | "onInputChange"
> & {
    readonly clear?: VoidFunction;
    readonly close: (options?: { blur?: boolean }) => void;
    readonly isItemDisabled?: (item: IItem) => boolean;
    readonly onIsOpenChange?: (isOpen: boolean) => void;
    readonly onSelectItems?: (values: IValue[], items: IItem[]) => any;
    readonly selectedValues: IValue[];
};

/**
 * Custom hook to setup the select component. This will handle all the configuration for Downshift.
 * @param config.clear                  Optional function to clear the selected values.
 * @param config.close                  Function to close the select menu.
 * @param config.disableMenuItems       Disable the menu items.
 * @param config.getLabel               Function to get the label of an item.
 * @param config.getValue               Function to get the value of an item.
 * @param config.isSingleSelect         Whether the select is a single select.
 * @param config.isSourcedSelect        Whether the select is sourced from an external source.
 * @param config.items                  Items to display in the select.
 * @param config.inputId                ID of the input element.
 * @param config.onInputChange Function to handle input change.
 * @param config.onIsOpenChange Function to handle when the menu is opened or closed.
 * @param config.onSelectItems Function to handle when items are selected.
 * @param config.selectedValues Selected values.
 */
export const useSelectSetup = <IItem extends {}, IValue = string>({
    clear = doNothing,
    close,
    disableMenuItems = false,
    isItemDisabled,
    getLabel,
    getValue,
    isMultipleSelectCheckbox = false,
    isSingleSelect = false,
    isSourcedSelect = false,
    items,
    inputId,
    onInputChange = doNothing,
    onIsOpenChange = doNothing,
    onSelectItems = doNothing,
    selectedValues,
}: ISelectSetupConfig<IItem, IValue>) => {
    const initialSelectedItems = items.filter((item) => (selectedValues ?? []).includes(getValue(item)));
    const multiplSelectionProps = useMultipleSelection<IItem>({ initialSelectedItems });

    const { addSelectedItem, selectedItems, setSelectedItems } = multiplSelectionProps;

    const [inputValue, setInputValue] = useState(
        isSourcedSelect && selectedItems.length > 0 ? getLabel(selectedItems[0]) : ""
    );

    const notSelectedItems = items.filter((item) => {
        return (
            // Don't filter out the selected items if it is a multiple select checkbox.
            (isMultipleSelectCheckbox ||
                !selectedItems.some((selectedItem) => getValue(selectedItem) === getValue(item))) &&
            // If true, it will return all the items that have not been selected. Otherwise, it will do the search.
            (isSourcedSelect || getLabel(item).toLowerCase().trim().includes(inputValue.toLowerCase().trim()))
        );
    });

    /**
     * Select some items and close the menu.
     * @param items Newly selected items.
     */
    const selectAndClose = (items: IItem[]) => {
        onSelectItems(items.map(getValue), items);
        close();
    };

    const {
        inputValue: comboboxInputValue,
        setInputValue: comboboxSetInputValue,
        ...comboboxProps
    } = useCombobox({
        inputValue,
        scrollIntoView: (listItem) => {
            const list = document.querySelector(".select-menu__list");
            if (listItem !== null && list !== null) {
                const isVisible = isScrollingElementVisible(listItem, list);
                if (!isVisible) {
                    listItem.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
        onIsOpenChange: ({ isOpen }) => {
            // When the menu is opened, make sure the selected items displayed matches the initial selected items. This
            // is needed because a user may have selected items but closed the menu without applying the changes.
            if (isOpen) {
                multiplSelectionProps.setSelectedItems(initialSelectedItems);
            }
            onIsOpenChange(isOpen ?? false);
        },
        inputId,
        items: notSelectedItems,
        isItemDisabled: (item) => isItemDisabled?.(item) || disableMenuItems,
        itemToString: (item) => (item === null ? "" : String(getValue(item))),
        stateReducer,
        onStateChange: ({ inputValue, type, selectedItem, isOpen }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.FunctionCloseMenu:
                case useCombobox.stateChangeTypes.InputClick:
                    // If the user clicks on the input, unlock the menu because the user explicitly wants to close the
                    // menu.
                    if (!isOpen) {
                        close({ blur: false });
                    }
                    break;
                case useCombobox.stateChangeTypes.InputChange:
                    // Clear the selection when the user changes the input because the options will change and the
                    // selected item may no longer be valid.
                    if (isSourcedSelect && selectedItems.length > 0) {
                        setSelectedItems([]);
                        clear();
                    }

                    onInputChange(inputValue);
                    setInputValue(inputValue ?? "");
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        if (isSingleSelect) {
                            selectAndClose([selectedItem]);
                        } else if (isMultipleSelectCheckbox) {
                            const isSelected = selectedItems.includes(selectedItem);

                            setSelectedItems(
                                isSelected
                                    ? selectedItems.filter((item) => item !== selectedItem)
                                    : [...selectedItems, selectedItem]
                            );

                            setInputValue("");
                        } else {
                            addSelectedItem(selectedItem);
                        }

                        comboboxProps.selectItem(null);
                    }

                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEscape:
                    close();
                    break;
                default:
                    break;
            }
        },
    });

    const { ref: menuContainerRef, ...menuListProps } = comboboxProps.getMenuProps();

    const selectMenuProps = {
        ...comboboxProps,
        ...multiplSelectionProps,
        allItems: items,
        menuListProps,
        close,
        disableMenuItems,
        isItemDisabled,
        onSelectItems,
        getValue,
        getLabel,
        notSelectedItems,
        selectedValues,
        isSingleSelect,
    };

    return { comboboxProps, multiplSelectionProps, selectMenuProps, inputValue, setInputValue, menuContainerRef };
};
