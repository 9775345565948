import { unreachableCaseError } from "@edgetier/utilities";
import { captureException } from "@sentry/react";

export enum BroadcastChannelEventTypes {
    LeaderExists = "leader-exists",
    WhoIsLeader = "who-is-leader",
}

export const broadcastChannel = new BroadcastChannel("tab-broadcast-channel");

// If this is true, the current tab is the "leader" tab meaning it is the one that the user is using or used last.
let isCurrentTabLeader = false;

// We initally set this to false and assume that no tab is the leader. We perform checks in setUpCurrentTab to determine
// if there is a leader and which tab it is.
let leaderExists = false;

/**
 * Get the current tab leader state.
 */
export const getIsCurrentTabLeader = () => isCurrentTabLeader;

/**
 * Reset the tab leader state.
 */
export const resetTabLeaderState = () => {
    isCurrentTabLeader = false;
    leaderExists = false;
};

/**
 * Assert that the current tab is the leader. Broadcasting this message to other tabs will set isCurrentTabLeader to
 * false in those tabs.
 */
const assertLeadership = () => {
    broadcastChannel.postMessage({ type: BroadcastChannelEventTypes.LeaderExists });
};

/**
 * Determine if this tab is the leader. If it is not, it will ask the other tabs if one of them is the leader. If none
 * of them respond, this tab will become the leader.
 */
export const determineLeader = () => {
    return new Promise<boolean>((resolve) => {
        leaderExists = false;

        if (isCurrentTabLeader) {
            leaderExists = true;
            return resolve(true);
        }

        // Ask the other tabs if one of them is the leader. If none of them respond, this tab becomes the leader.
        // Even if this timeout is not long enough sometimes, the worst case scenario is that the wrong tab will be the
        // leader. We should still never end up with two leaders.
        broadcastChannel.postMessage({ type: BroadcastChannelEventTypes.WhoIsLeader });
        setTimeout(() => {
            if (!leaderExists) {
                isCurrentTabLeader = true;
                leaderExists = true;
                assertLeadership();

                return resolve(true);
            }
            return resolve(false);
        }, 1000);
    });
};

/**
 * Make the current tab the leader and let the other tabs know.
 */
const makeCurrentTabLeader = () => {
    isCurrentTabLeader = true;
    assertLeadership();
};

// Listen for messages from other tabs
broadcastChannel.addEventListener("message", (event: MessageEvent<{ type: BroadcastChannelEventTypes }>) => {
    if (!("type" in event.data) || typeof event.data.type !== "string") {
        captureException(new Error("Message received from broadcast channel does not contain a type."));
        return;
    }

    switch (event.data.type) {
        case BroadcastChannelEventTypes.WhoIsLeader:
            // If this tab is the leader, inform the other tabs.
            if (isCurrentTabLeader) {
                assertLeadership();
            }
            break;
        case BroadcastChannelEventTypes.LeaderExists:
            // If another tab is already the leader, stop this tab from becoming one.
            leaderExists = true;
            isCurrentTabLeader = false;
            break;
        default:
            const error = unreachableCaseError(event.data.type);
            captureException(error);
    }
});

/**
 * This is the function that runs when a tab is opened. It peforms checks to make sure the leader is set correctly.
 * This is only exported for testing.
 */
export const setUpCurrentTab = () => {
    // If a tab is opened and visible, it should become the leader. A tab can be opened without being visible if the
    // user opens a link in a new tab. In this case, the initial tab should remain the leader.
    if (!document.hidden) {
        makeCurrentTabLeader();
    }

    // Determine which tab should be the leader now that this one has been created.
    determineLeader();

    // If the tab becomes visible, it should become the leader.
    document.addEventListener("visibilitychange", () => {
        if (!document.hidden) {
            makeCurrentTabLeader();
        }
    });
};

// If not running in a test enviroment, this should be called immediately.
if (process.env.NODE_ENV !== "test") {
    setUpCurrentTab();
}
