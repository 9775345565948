import axios from "utilities/axios";
import requestCustomerUrls from "utilities/request-customer-urls";
import urlJoin from "url-join";
import { AxiosRequestConfig, CancelTokenSource } from "axios";
import { ChatEventType, IChatEvent, Url } from "@edgetier/types";
import { getSocket } from "./socket";
import { getUserId } from "utilities/local-storage";
import { IResumeChat } from "redux/modules/chat/chat.types";
import { ISettings } from "redux/application.types";
import ChatEvent from "./chat-event";

export const EVENT_ROUTE = "/events";

/**
 * Invoke an Axios cancel token to cancel any ongoing requests. This may be necessary as an agent leaves a chat or signs
 * out while there are requests in progress.
 * @param cancelTokenSourceLookup Chat token to Axios cancel token source lookup.
 * @param identifier              Identifier to find any cancel tokens to cancel.
 * @returns                       The same lookup with the entry for the chat token removed.
 */
export function cancelRequests(cancelTokenSourceLookup: { [index: string]: CancelTokenSource }, identifier: string) {
    if (identifier in cancelTokenSourceLookup) {
        cancelTokenSourceLookup[identifier].cancel();
        const { [identifier]: removed, ...others } = cancelTokenSourceLookup;
        return others;
    } else {
        return cancelTokenSourceLookup;
    }
}

/**
 * Send an event to the backend.
 * @param chatToken Identifier for the event being sent.
 * @param data      Post data.
 * @returns         Axios promise.
 */
export async function postEvent<ChatEvent = IChatEvent>(chatEventType: ChatEventType, chatToken: string, data?: {}) {
    const socket = await getSocket();
    return axios.post<ChatEvent>(urlJoin(Url.Chat, chatToken, EVENT_ROUTE), {
        ...data,
        chatEventType,
        socketId: socket.id,
    });
}

/**
 * Get the chats currently with an agent. This happens when an agent enables chat or reconnects after a socket
 * disconnection and reconnection.
 * @param configuration Axios configuration to cancel requests.
 * @returns             Promise of chats belonging to the agent.
 */
export async function requestChats(configuration: AxiosRequestConfig = {}): Promise<IResumeChat[]> {
    const userId = getUserId();
    if (userId === null) {
        return Promise.resolve([]);
    }

    const params = { completed: false, lastActiveUserId: userId };
    const chats = await axios.get<IResumeChat[]>(Url.Chat, { ...configuration, params });
    return chats.data;
}

/**
 * Decorate a chat with its customerUrls and the app settings.
 * @param chat               A single chat as per server definition
 * @param settings           app settings to live with chat lifespan of current interaction
 * @param configuration      optional axios config for cancelling request
 * @return                   Chat query
 */
export async function decorateChat(
    chat: IResumeChat,
    settings: ISettings,
    configuration: AxiosRequestConfig = {}
): Promise<IResumeChat> {
    try {
        const customerUrls = await requestCustomerUrls(chat.chatId, configuration);
        return { ...chat, customerUrls, settings };
    } catch {
        return { ...chat, customerUrls: [], settings };
    }
}

/**
 * Create a chatIndices generator that allows embagoing an index (recently finished chats)
 * Private factory function exported for testing only
 */
export function ChatIndicesFactory() {
    const CHAT_INDEX_MAX_COUNT = 6;
    let embargoedIndices: number[] = [];

    /**
     * Get the smallest number that isn't already taken or embargoed,
     * Clears embargoedIndices once run
     * @param   chats  chats as an object
     * @return         the smallest number that isn't currently a 'chatIndex' or in embargoedIndices
     */
    function getNextIndex(chats: { [chatToken: string]: { chatIndex?: number } }) {
        const arrayOfChatIndices = Object.values(chats)
            .map(({ chatIndex }) => chatIndex)
            .concat(embargoedIndices)
            .filter(Number.isInteger)
            .sort((one, two) => (one as number) - (two as number));
        let nextIndex: number = 0;
        while (nextIndex === arrayOfChatIndices[nextIndex]) {
            nextIndex += 1;
        }
        embargoedIndices = [];
        return nextIndex % CHAT_INDEX_MAX_COUNT;
    }

    /**
     * Prevents an index from being called on the next rotation
     * @param   chatIndex  any chatIndex number
     */
    function embargoIndex(chatIndex: number) {
        embargoedIndices.push(chatIndex);
    }

    return {
        next: getNextIndex,
        hold: embargoIndex,
        reset: () => (embargoedIndices = []),
    };
}

/**
 * Force disable chat.
 */
export const forceDisableChat = async () => {
    // The logic for force disabling a chat is in the ChatService and coupled tightly with redux so that code cannot be
    // exported. Instead, force that code to be executed by manually calling the ForceDisableChat socket listener.
    const socket = await getSocket();
    const listeners = socket.listeners(ChatEvent.ForceDisableChat);
    listeners.forEach((listener) => {
        listener();
    });
};

/**
 * manage "ChatIndices" via the provide methods: next, hold & reset
 */
export const chatIndices = ChatIndicesFactory();
