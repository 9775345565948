import { FunctionComponent, memo, useCallback } from "react";
import { IProps } from "./assignment-details.types";
import { useEmailAssignments } from "./assignment-details.utilities";
import { DetailsSection, DetailsSectionTitle } from "@edgetier/client-components";
import AssignmentDetailsField from "./assignment-details-field";
import { SpinnerUntil } from "@edgetier/components";
import { IEmailAssignment, Permission } from "@edgetier/types";
import { compareDesc, isAfter } from "date-fns";
import AssignAgents from "components/search-emails/assign-agents";
import VerifyPermission from "components-for/verify-permission";
import "./assignment-details.scss";

/**
 * Show assignment details for an email.
 * @param props.emailId The ID of the email who's details we're showing.
 */
const AssignmentDetails: FunctionComponent<IProps> = ({ emailId, interactionCompleted }) => {
    const {
        data: emailAssignments,
        isLoading,
        refetch,
        isError,
    } = useEmailAssignments(
        emailId,
        {},
        {
            select: (assignments) => {
                return assignments.sort((one, two) =>
                    compareDesc(new Date(one.createDateTime), new Date(two.createDateTime))
                );
            },
        }
    );

    const onSuccess = () => {
        refetch();
    };

    /**
     * Check if an email assignment is the last active assignment for the interaction
     * @param assignment    The selected assignment
     */
    const checkIfLastAssignment = useCallback(
        (assignment: IEmailAssignment) => {
            if (interactionCompleted) return false;

            const activeAssignments = (emailAssignments ?? []).filter(
                (emailAssignment) =>
                    emailAssignment.assignedUntil === null ||
                    isAfter(new Date(emailAssignment.assignedUntil), new Date())
            );

            return activeAssignments.includes(assignment) && activeAssignments.length === 1;
        },
        [emailAssignments, interactionCompleted]
    );

    return (
        <div className="assignment-details">
            <DetailsSection>
                <DetailsSectionTitle title="Assignments">
                    {!interactionCompleted && (
                        <VerifyPermission permission={Permission.AssignInteraction}>
                            <AssignAgents emailIds={[emailId]} onSuccess={onSuccess} />
                        </VerifyPermission>
                    )}
                </DetailsSectionTitle>
                <SpinnerUntil<IEmailAssignment[]> isReady={!isLoading} data={emailAssignments}>
                    {(data) =>
                        Array.isArray(data) && data.length > 0 ? (
                            <>
                                {data.map((assignment, index) => (
                                    <AssignmentDetailsField
                                        assignment={assignment}
                                        emailId={emailId}
                                        isLastAssignment={checkIfLastAssignment(assignment)}
                                        interactionCompleted={interactionCompleted}
                                        key={`${assignment.emailId}-${assignment.userId}-${index}`}
                                    />
                                ))}
                            </>
                        ) : (
                            <div className="assignment-details__no-assignments">
                                {isError ? "Error loading assignments." : "No agents have been assigned to this email."}
                            </div>
                        )
                    }
                </SpinnerUntil>
            </DetailsSection>
        </div>
    );
};

export default memo(AssignmentDetails);
