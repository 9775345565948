import { UseComboboxState, UseComboboxStateChangeOptions } from "downshift";
import { useCombobox } from "downshift";

/**
 * Custom state reducer for Downshift. This will handle the state changes for the select component.
 * @param state Current state of the select component.
 * @param actionAndChanges The changes that Downshift is trying to apply to the state.
 * @returns New state of the select component.
 */
export const stateReducer = <IItem extends {}>(
    state: UseComboboxState<IItem>,
    actionAndChanges: UseComboboxStateChangeOptions<IItem>
) => {
    const { changes, type } = actionAndChanges;
    switch (type) {
        // We want to control Downshift's isOpen state in these circumstances.
        // InputKeyDownEnter: We don't support selecting an item when a user presses enter so it shouldn't close.
        // InputBlur: We heavily control the blur and focus behaviour in the Select component.
        // ItemClick: There are instances (e.g clicking an multi-select item) where we want to keep the menu open
        // when an item is clicked.
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
        case useCombobox.stateChangeTypes.ItemClick:
            return {
                ...changes,
                isOpen: state.isOpen,
            };
        default:
            return changes;
    }
};
