import { ampersandList } from "@edgetier/client-components";

interface ISelectPreviewConfig<IItem> {
    readonly description: string;
    readonly getLabel: (item: IItem) => string;
    readonly isAllFilter?: boolean;
    readonly isEmptyLabel?: string;
    readonly isOpen: boolean;
    readonly isSingleSelect: boolean;
    readonly items: IItem[];
    readonly previewPlaceholder?: JSX.Element | string;
    readonly searchPlaceholder: string;
    readonly itemsSelectedBeforeOpen: IItem[];
    readonly useAllItemsSelectedPreviewMessage?: boolean;
}

/**
 * Custom hook to get the preview text for the select component.
 * @param config.description Description of the items.
 * @param config.getLabel    Function to get the label of an item.
 * @param config.isAllFilter Whether the select is an all filter.
 * @param config.isEmptyLabel Optional label to display when no items are selected.
 * @param config.isOpen      Whether the select is open.
 * @param config.isSingleSelect Whether the select is a single select.
 * @param config.items       Items to display in the select.
 * @param config.previewPlaceholder Optional placeholder to display when no items are selected.
 * @param config.searchPlaceholder Placeholder to display when the select is open.
 * @param config.selectedItems Selected items.
 * @param config.useAllItemsSelectedPreviewMessage Whether to display a message when all items are selected.
 * @returns Preview text.
 */
export const useSelectPreview = <IItem extends {}>({
    description,
    getLabel,
    isAllFilter = false,
    isEmptyLabel,
    isOpen,
    isSingleSelect,
    items,
    previewPlaceholder,
    searchPlaceholder,
    itemsSelectedBeforeOpen,
    useAllItemsSelectedPreviewMessage = true,
}: ISelectPreviewConfig<IItem>) => {
    if (isOpen) {
        return searchPlaceholder;
    }

    if (typeof previewPlaceholder === "string") {
        return previewPlaceholder;
    }

    const selectedCount = itemsSelectedBeforeOpen.length;
    const isEmpty = selectedCount === 0;
    const allSelectedLabel = `All ${description}${description[description.length - 1] !== "s" && "s"} selected`;

    if (isEmpty) {
        if (typeof isEmptyLabel !== "undefined") {
            return isEmptyLabel;
        }

        if (isAllFilter && !isSingleSelect) {
            return allSelectedLabel;
        }

        return `No ${description} selected`;
    }

    const allSelected = useAllItemsSelectedPreviewMessage && selectedCount === items.length;

    if (!isSingleSelect && allSelected) {
        return allSelectedLabel;
    }

    return ampersandList(itemsSelectedBeforeOpen.map(getLabel));
};
