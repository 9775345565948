import { FunctionComponent, memo } from "react";
import { IProps } from "./popover-header.types";
import "./popover-header.scss";

/**
 * A component that renders a header section for a popover.
 *
 * @param title - The text to display as the header title
 * @returns  A div container with an h3 heading displaying the title
 */
const PopoverHeader: FunctionComponent<IProps> = ({ title }) => {
    return (
        <div data-testid="popover-header" className="popover-header">
            <h3>{title}</h3>
        </div>
    );
};

export default memo(PopoverHeader);
