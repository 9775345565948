export const markdownCharacters = [
    "#",
    "(",
    ")",
    "*",
    "+",
    "<",
    ">",
    "[",
    "\\",
    "]",
    "_",
    "`",
    "{",
    "|",
    "}",
    "~",
    "-", // The hyphen has to be first or last because otherwise the regex will think it's a character range.
] as const;

type MarkdownCharacters = (typeof markdownCharacters)[number];

export const markdownCharacterRegex = new RegExp(`[${"\\" + markdownCharacters.join("\\")}]`, "g");

const urlRegex = new RegExp("(([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:\\/~+#-]*[\\w@?^=%&\\/~+#-])?)", "g");

/**
 * Escape any markdown characters in a text string but ignore URLs.
 * @param text
 * @returns A string with all markdown characters escaped e.g "# a heading" returns "\# a heading"
 */
export const escapeMarkdown = (text: string) => {
    const textArray: string[] = [];

    // The last index where we know there is no URL.
    let lastIndex = 0;
    let match;
    while ((match = urlRegex.exec(text)) !== null) {
        const url = match[0];

        // All of the text before the URL.
        const textUntilUrl = text.substring(lastIndex, match.index);
        const cleaned = textUntilUrl.replace(markdownCharacterRegex, "\\$&");

        textArray.push(cleaned, url);
        lastIndex = urlRegex.lastIndex;
    }

    const remainderOfText = text.substring(lastIndex, text.length);
    textArray.push(remainderOfText.replace(markdownCharacterRegex, "\\$&"));

    return textArray.join("");
};

/**
 * Escape specific markdown characters in a text string, note this may not work as expected if the character is part of a URL.
 * @param text THe text to escape
 * @param characters The specific markdown characters to escape
 * @returns A string with the specific markdown character escaped
 */
export const escapeSpecificMarkdownCharacters = (text: string, characters: MarkdownCharacters[]) => {
    const charactersRegex = new RegExp(`[${"\\" + characters.join("\\")}]`, "g");
    return text.replace(charactersRegex, "\\$&");
};
