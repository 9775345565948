import { Fragment, FunctionComponent, memo, useCallback, useEffect, useRef } from "react";
import { IProps } from "./assignment-details-remove-agent.types";
import { Button, Tooltip } from "@edgetier/components";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import Modal, { ModalContent, ModalFooter, ModalHeader, useModal } from "@edgetier/modal";
import { hotToastOperations } from "utilities-for/toast";
import axios from "utilities/axios";
import Axios from "axios";
import { IEmailAssignment, Url } from "@edgetier/types";
import { getServerError } from "@edgetier/utilities";
import { useQueryClient } from "react-query";
import "./assignment-details-remove-agent.scss";

/**
 * Remove assigned agent with confirmation modal
 * @param props.emailId         Interaction email ID
 * @param props.emailUserAssignmentId
 * @param props.userId          The agent ID assigned
 * @param props.isLastAssignment Boolean flag for checking if the assignment is the only one active
 */
const AssignmentDetailsRemoveAgent: FunctionComponent<IProps> = ({
    emailId,
    emailUserAssignmentId,
    userId,
    isLastAssignment,
}) => {
    const queryClient = useQueryClient();
    const { isOpen, modalProps, openModal, closeModal } = useModal();
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    /**
     * Request to remove the agent from an assignment
     */
    const removeAgent = useCallback(async () => {
        try {
            await axios.delete(
                Url.EmailUserAssignments.replace(":emailId", emailId.toString()).replace(
                    ":emailUserAssignmentId",
                    emailUserAssignmentId.toString()
                )
            );
            hotToastOperations.showSuccessToast("Success", "The agent assigned has been removed.");

            queryClient.setQueriesData<IEmailAssignment[]>(
                Url.EmailsAssignments.replace(":emailId", emailId.toString()),
                (emailAssignments) => {
                    return (emailAssignments ?? []).filter(
                        (existingEmailAssignment) => existingEmailAssignment.userId !== userId
                    );
                }
            );

            if (isOpen) {
                closeModal();
            }
        } catch (error) {
            hotToastOperations.showErrorToast(getServerError(error));
        }
    }, [closeModal, emailId, emailUserAssignmentId, isOpen, queryClient, userId]);

    return (
        <Fragment>
            <Modal className="brand-variable-type-toggle" {...modalProps}>
                <ModalHeader>
                    <h2>Remove assigned agent</h2>
                </ModalHeader>
                <ModalContent>
                    <p>
                        Are you sure you want to remove the assigned agent ? Removing this agent will return the email
                        back to the queue.
                    </p>
                </ModalContent>
                <ModalFooter>
                    <Button styleName="negative" icon={faTimes} onClick={removeAgent}>
                        Remove
                    </Button>
                </ModalFooter>
            </Modal>
            <Tooltip content={`Remove Agent`} useArrow>
                <Button
                    type="button"
                    styleName="negative"
                    isSmall
                    isIconOnly
                    icon={faTimes}
                    outline
                    onClick={() => {
                        if (isLastAssignment) {
                            openModal();
                        } else {
                            removeAgent();
                        }
                    }}
                />
            </Tooltip>
        </Fragment>
    );
};

export default memo(AssignmentDetailsRemoveAgent);
