import { FunctionComponent, memo } from "react";
import { IProps } from "./popover-footer.types";
import "./popover-footer.scss";

/**
 * PopoverFooter component renders a footer section within a popover.
 * It serves as a container for any footer content that needs to be displayed at the bottom of a popover.
 *
 * @param children - React child elements to be rendered within the footer
 * @returns A div element with the class 'popover-footer' containing the children elements
 */
const PopoverFooter: FunctionComponent<IProps> = ({ children }) => {
    return (
        <div data-testid="popover-footer" className="popover-footer">
            {children}
        </div>
    );
};

export default memo(PopoverFooter);
