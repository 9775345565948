import { FallbackRender, showReportDialog } from "@sentry/react";

import ChunkLoadErrorFallback from "../chunk-load-error-fallback";
import ErrorFallback from "../error-fallback";

/**
 * Function for rendering different error fallback components based on error type.
 * @param {object} errorData - The error data to determine the type of error. This is comming from the Sentry ErrorBoundary
 * @returns {JSX.Element} ErrorBuilder function component.
 */
const ErrorBuilder: FallbackRender = (errorData) => {
    const { error } = errorData;
    if (error instanceof Error && error.name === "ChunkLoadError") {
        return <ChunkLoadErrorFallback />;
    } else {
        showReportDialog({ eventId: errorData.eventId ?? undefined });
        return <ErrorFallback />;
    }
};

export default ErrorBuilder;
