import classNames from "classnames";
import { FunctionComponent, memo, useEffect, useRef, useState } from "react";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DropDownMenuProvider from "../drop-down-menu-provider";

import { IProps } from "./drop-down-menu.types";
import "./drop-down-menu.scss";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

// CSS class added to the element when the menu is open.
export const OPEN_CLASS = "drop-down-menu--open";
export const DISABLED_CLASS = "drop-down-menu--disabled";

/**
 * Drop-down menu that will close when the user clicks outside it.
 * @param props.children   The content of the drop-down menu.
 * @param props.disabled   If the drop-down menu is disabled.
 * @param props.icon       The icon to display in the drop-down menu.
 * @param props.onClick    Function that will run when the drop-down menu is clicked.
 * @param props.useIcon    If the icon should be displayed.
 */
export const DropDownMenu: FunctionComponent<IProps> = ({
    children,
    disabled = false,
    icon = faCog,
    onClick: onClickCallback,
    useIcon = true,
}) => {
    const element = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    /**
     * If the user clicks anywhere outside this component it should close.
     * @param mouseEvent Any click event.
     */
    const onClickOutside = (mouseEvent: MouseEvent): void => {
        const target = mouseEvent.target;
        const currentElement = element.current;
        if (currentElement === null || !(target instanceof Element)) {
            return;
        }
        setIsOpen((isOpen) => (isOpen && !currentElement.contains(target) ? false : isOpen));
    };

    useEffect(() => {
        document.addEventListener("mousedown", onClickOutside);
        return () => document.removeEventListener("mousedown", onClickOutside);
    }, []);

    /**
     * Toggle the menu on click.
     */
    const onClick = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
            onClickCallback?.();
        }
    };
    return (
        <DropDownMenuProvider isOpen={isOpen} toggleIsOpen={onClick}>
            <div
                className={classNames("drop-down-menu", {
                    [OPEN_CLASS]: isOpen,
                    [DISABLED_CLASS]: disabled,
                })}
                ref={element}
            >
                <div onClick={onClick}>
                    {useIcon && <FontAwesomeIcon title="drop down menu icon" icon={icon} />}
                    <span className="drop-down-menu__icon">
                        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="xs" />
                    </span>
                </div>
                {children}
            </div>
        </DropDownMenuProvider>
    );
};

export default memo(DropDownMenu);
