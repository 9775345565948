import { createContext } from "react";
import { doNothing } from "@edgetier/utilities";

import { IDropDownMenuContext } from "./drop-down-menu-context.types";

export const defaultContext: IDropDownMenuContext = {
    isOpen: false,
    toggleIsOpen: doNothing,
};

const DropDownMenuContext = createContext<IDropDownMenuContext>(defaultContext);
export default DropDownMenuContext;
